export const works = [
    {
        title: "“Transubstantiation,” The Cincinnati Review Robert and Adele Schiff Award, Honorable Mention for Fiction",
        date: "2024",
        url: "https://www.cincinnatireview.com/contests/announcing-our-2024-contest-winners/"
    },
    { 
        title: "“Accounting,” Gully Magazine",
        date: "2024",
        url: "https://gully-mag.com/accounting/" 
    },
    { 
        title: "“Self-Conscious,” Regarding Ingres: Fourteen Short Stories",
        date: "2023",
        url: "https://www.rizzoliusa.com/book/9780847899128/" 
    },
    { 
        title: "“In Session,” KGB",
        date: "2023",
        url: "https://kgbbarlit.com/fiction/session" 
    },
];